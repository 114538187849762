








































import Vue from "vue";
import formSchemaSource from "@/common/form/case";
export default Vue.extend({
  data() {
    return {
      id: undefined,
      formSchemaSource,
      form: {},
      home: {},
      data: {},
      cases: this.$store.state.allData.cases,
    };
  },
  mounted() {
    console.log(!!this.id);
    this.getAllDatas();
    this.doAnimation();
  },

  methods: {
    getAllDatas() {
      let data = this.$store.state.allData;
      this.data = data["case"][0];

      this.id = data["case"][0] && data["case"][0].id;
    },

    goService(id: number) {
      console.log(id);
    },
    doAnimation() {
      this.$ScrollReveal().reveal(".banner", {
        reset: true,
        duration: 1500,
        distance: "20%",
        origin: "top",
        scale: 0.9,
      });
    },
  },
});
